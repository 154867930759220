import { config } from './config';
import { getAuth } from '../common/firebase';
import { SessionContextData } from './Session/SessionContext';
import { downloadFileFromBlob } from './download';
import { GameLoaderType } from './domain/game';

export function prefixGraphUrl(path: string): string {
  return `${config.graph}/${path}`;
}

interface PreviewUrlOptions {
  minTimeForAds?: number;
  noTestAds: boolean;
}

export async function generateSubmissionPreviewUrl(submissionId: string, options: PreviewUrlOptions): Promise<string> {
  const token = await checkLoggin();
  const url = new URL(prefixGraphUrl(`gameframe/${submissionId}/index.html`));
  addCommonSearchParam(url, options, token);
  url.searchParams.set('referrer', window.location.href);
  return parseUrl(url);
}

export async function generateStandalonePreviewUrl(
  standaloneId: string,
  loader: GameLoaderType,
  options: PreviewUrlOptions,
): Promise<string> {
  const token = await checkLoggin();
  const url = new URL(prefixGraphUrl(`gameframe-standalone/${standaloneId}/index.html`));
  addCommonSearchParam(url, options, token);
  url.searchParams.set('gameLoaderType', loader);
  return parseUrl(url, ['useQATool', 'gameLoaderType', 'mode']);
}

async function checkLoggin(): Promise<string> {
  const auth = getAuth();
  if (!auth.currentUser) {
    const msg = 'Not logged in. Please refresh your page';
    throw new Error(msg);
  }
  return await auth.currentUser.getIdToken();
}

function addCommonSearchParam(url: URL, options: PreviewUrlOptions, token: string) {
  const { minTimeForAds } = options;
  url.searchParams.set('token', token);
  if (minTimeForAds !== undefined) {
    url.searchParams.set('minTimeForAds', `${minTimeForAds}`);
  }
  url.searchParams.set('noTestAds', `${options.noTestAds}`);
}

function parseUrl(url: URL, excludedKeys: string[] = []): string {
  const paramsFromUrl = new URLSearchParams(window.location.search);
  paramsFromUrl.forEach((paramValue: string, paramKey: string) => {
    if (!excludedKeys.includes(paramKey) && !url.searchParams.has(paramKey)) {
      url.searchParams.append(paramKey, paramValue);
    }
  });

  return url.toString();
}

const DOWNLOAD_TAG_LENGTH_ENDPOINT = prefixGraphUrl('tag-description-lengths');
const DOWNLOAD_GAME_LENGTH_ENDPOINT = prefixGraphUrl('game-description-lengths');

async function processDownloadDescriptionLengthReport(session: SessionContextData, endpoint: string, filename: string) {
  if (!session.isLoggedIn()) {
    return Promise.reject('[upload] not authenticated');
  }
  const token = await session.getUser().firebaseUser.getIdToken();

  const resp = await fetch(endpoint.toString(), {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (resp.status === 200) {
    const blob = await resp.blob();
    return downloadFileFromBlob(blob, filename, {
      fileType: 'text/csv',
      extension: 'csv',
    });
  } else {
    const text = await resp.text();
    console.error(text);
    throw new Error(text);
  }
}

export async function downloadDescriptionLengthReport(session: SessionContextData, type: 'tag' | 'game') {
  switch (type) {
    case 'tag':
      return await processDownloadDescriptionLengthReport(session, DOWNLOAD_TAG_LENGTH_ENDPOINT, 'tag_description_length');
    case 'game':
      return await processDownloadDescriptionLengthReport(session, DOWNLOAD_GAME_LENGTH_ENDPOINT, 'game_description_length');
    default:
      throw new Error('Unknown type for description length report');
  }
}
