import React from 'react';
import { RouteComponentProps } from 'react-router';
import SubmissionPreviewLoader from './SubmissionPreviewLoader';
import AuthenticatedRoute from '../../Session/AuthenticatedRoute';
import { GameLoaderType, standaloneLoaders } from '../../../../common/domain/game';
import StandalonePreviewLoader from './StandalonePreviewLoader';

export enum PreviewMode {
  SUBMISSION = 'submission',
  STANDALONE = 'standalone',
}

export enum QANextStep {
  REV_FORM = 'revForm',
  REAPPROVAL = 'reapproval',
  SYNC = 'sync',
  UPDATE_COMPLETE = 'updateComplete',
}

export const PREVIEW_ROUTE = '/preview/:id';

interface PreviewRouteOptions {
  gameUrl?: string;
  minTimeForAds?: number;
  noTestAds?: boolean;
  secretSkipVideoAndFallback?: boolean;
  sdk_debug?: boolean;
  QANextStep?: QANextStep;
  draft?: boolean;
  allowWebCam?: boolean;
}

const DEFAULT_OPTIONS: PreviewRouteOptions = { sdk_debug: true };

export function generateStandalonePreviewRoute(standaloneId: string, gameLoaderType: GameLoaderType, options: PreviewRouteOptions = {}) {
  let route = PREVIEW_ROUTE.replace(':id', standaloneId);
  const searchParams = generateCommonSearchParams(options);
  searchParams.set('mode', PreviewMode.STANDALONE);
  searchParams.set('gameLoaderType', gameLoaderType);
  const searchParamsString = searchParams.toString();
  if (searchParamsString) {
    route = `${route}?${searchParamsString}`;
  }
  return route;
}

export function generateSubmissionPreviewRoute(
  submissionId: string,
  options: PreviewRouteOptions = {},
): { href: string; pathname: string; query?: string } {
  let route = PREVIEW_ROUTE.replace(':id', submissionId);
  const pathname = route;
  const searchParams = generateCommonSearchParams(options);
  searchParams.set('mode', PreviewMode.SUBMISSION);
  const searchParamsString = searchParams.toString();
  if (searchParamsString) {
    route = `${route}?${searchParamsString}`;
  }
  return {
    href: route,
    pathname,
    query: `?${searchParamsString}`,
  };
}

function generateCommonSearchParams(options: PreviewRouteOptions) {
  const optionsWithDefault = { ...DEFAULT_OPTIONS, ...options };
  const searchParams = new URLSearchParams();
  const optionsKeys: (keyof PreviewRouteOptions)[] = Object.keys(optionsWithDefault) as (keyof PreviewRouteOptions)[];
  optionsKeys.forEach((optionKey: keyof PreviewRouteOptions) => {
    if (optionsWithDefault[optionKey] !== undefined) {
      if (typeof optionsWithDefault[optionKey] === 'string') {
        searchParams.set(optionKey.toString(), optionsWithDefault[optionKey] as string);
      } else {
        searchParams.set(optionKey.toString(), JSON.stringify(optionsWithDefault[optionKey]));
      }
    }
  });
  if (searchParams.get('allowWebCam') === 'false') {
    // try to keep this private
    searchParams.delete('allowWebCam');
  }
  return searchParams;
}

interface PreviewRouteParams {
  id: string;
}

export interface PreviewOptions {
  minTimeForAds?: number;
  noTestAds: boolean;
  nextStep?: QANextStep;
  draft: boolean;
}

const PreviewRouteComponent: React.FunctionComponent<RouteComponentProps<PreviewRouteParams>> = (props) => {
  const { search } = props.location;
  const { id } = props.match.params;
  const paramsFromUrl = new URLSearchParams(search);
  const mode = (paramsFromUrl.get('mode') || PreviewMode.SUBMISSION) as PreviewMode;
  const gameLoaderType = paramsFromUrl.get('gameLoaderType') as GameLoaderType;
  const previewOptions: PreviewOptions = {
    minTimeForAds: paramsFromUrl.get('minTimeForAds') ? Number(paramsFromUrl.get('minTimeForAds')) : undefined,
    noTestAds: paramsFromUrl.get('noTestAds') === 'true',
    nextStep: paramsFromUrl.get('QANextStep') as QANextStep | undefined,
    draft: paramsFromUrl.get('draft') === 'true',
  };

  if (mode === PreviewMode.SUBMISSION) {
    return <SubmissionPreviewLoader submissionId={id} previewOptions={previewOptions} />;
  }
  if (mode === PreviewMode.STANDALONE) {
    if (!gameLoaderType || !standaloneLoaders.includes(gameLoaderType)) {
      return null;
    }
    return <StandalonePreviewLoader standaloneId={id} gameLoaderType={gameLoaderType} previewOptions={previewOptions} />;
  }
  return null;
};

const PreviewRoute: React.FunctionComponent = () => {
  return <AuthenticatedRoute path={PREVIEW_ROUTE} component={PreviewRouteComponent} />;
};

export default PreviewRoute;
