import React from 'react';
import { StandalonePreviewUrlProvider } from './PreviewUrlProvider';
import QATool from '../UserDeveloper/QATool/QATool';
import { PreviewOptions } from './PreviewRoute';
import { GameLoaderType } from '../../../../common/domain/game';

interface StandalonePreviewLoaderInput {
  standaloneId: string;
  gameLoaderType: GameLoaderType;
  previewOptions: PreviewOptions;
}
const StandalonePreviewLoader: React.FC<StandalonePreviewLoaderInput> = ({ standaloneId, gameLoaderType, previewOptions }) => {
  return (
    <StandalonePreviewUrlProvider
      standaloneId={standaloneId}
      gameLoaderType={gameLoaderType}
      minTimeForAds={previewOptions.minTimeForAds}
      noTestAds={previewOptions.noTestAds}
      previewComponent={(url) => <QATool gameName={'Your Game'} previewUrl={url} previewOptions={previewOptions} />}
    />
  );
};

export default StandalonePreviewLoader;
