import * as React from 'react';
import CzyLoader from '../../../../common/Loader';
import { generateSubmissionPreviewUrl, generateStandalonePreviewUrl } from '../../../../common/util';
import { GameLoaderType } from '../../../../common/domain/game';

export interface CommonProps {
  minTimeForAds?: number;
  noTestAds: boolean;
  previewComponent: (previewUrl: string) => JSX.Element;
}

export interface SubmissionProps extends CommonProps {
  submissionId: string;
}

export interface StandaloneProps extends CommonProps {
  standaloneId: string;
  gameLoaderType: GameLoaderType;
}

interface PreviewUrlProps extends CommonProps {
  url?: string;
}

interface State {
  url: string | null;
}

class PreviewUrlProvider extends React.Component<PreviewUrlProps> {
  render() {
    if (!this.props.url) {
      return <CzyLoader />;
    }
    return <>{this.props.previewComponent(this.props.url)}</>;
  }
}

export class SubmissionPreviewUrlProvider extends React.Component<SubmissionProps, State> {
  constructor(props: SubmissionProps) {
    super(props);
    this.state = {
      url: null,
    };
  }

  async componentDidMount() {
    const { submissionId, minTimeForAds, noTestAds } = this.props;
    const url = await generateSubmissionPreviewUrl(submissionId, { minTimeForAds, noTestAds });
    this.setState({ url: url });
  }

  render() {
    if (this.state.url) {
      return <PreviewUrlProvider {...this.props} url={this.state.url} />;
    }
    return null;
  }
}

export class StandalonePreviewUrlProvider extends React.Component<StandaloneProps, State> {
  constructor(props: StandaloneProps) {
    super(props);
    this.state = {
      url: null,
    };
  }

  async componentDidMount() {
    const { standaloneId, gameLoaderType, minTimeForAds, noTestAds } = this.props;
    const url = await generateStandalonePreviewUrl(standaloneId, gameLoaderType, { minTimeForAds, noTestAds });
    this.setState({ url: url });
  }

  render() {
    if (this.state.url) {
      return <PreviewUrlProvider {...this.props} url={this.state.url} />;
    }
    return null;
  }
}
