import { Backdrop, Box, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import TermsLink, { termsVersion } from '../../../common/components/TermsLink';
import { StyledButton } from '../../../common/Styleguide/Common/Button';
import { StyledBodyText } from '../../../common/Styleguide/Common/Text';
import { DeveloperProfileGranularUpdateInput, granularSaveAccount } from '../Content/Profile/profile-graphql';
import { HEADER_HEIGHT } from '../Header/Header';

interface AcceptTnCProps {}

const AcceptTnc: React.FC<AcceptTnCProps> = () => {
  const [hasAccepted, setHasAccepted] = React.useState(false);
  const history = useHistory();

  const handleSubmit = async () => {
    const input: DeveloperProfileGranularUpdateInput = {
      acceptTermsVersion: termsVersion,
    };

    try {
      await granularSaveAccount(input);
      history.go(0);
    } catch (e) {}
  };

  return (
    <Backdrop open sx={{ backgroundColor: '#1a1b28d4', top: HEADER_HEIGHT, zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Dialog open>
        <DialogTitle id="alert-dialog-slide-title" sx={{ mt: 1, pb: 0 }}>
          Terms & conditions
        </DialogTitle>
        <DialogContent>
          <StyledBodyText id="alert-dialog-slide-description" color="white60" sx={{ textAlign: 'center', px: 2 }}>
            We’ve updated our Terms and Conditions. Please make sure you agree with them so you can keep using our platform.
          </StyledBodyText>
        </DialogContent>
        <DialogActions sx={{ flexDirection: 'column', pt: 1, pb: 2.5 }}>
          <Box sx={{ pb: 1.5 }}>
            <Checkbox checked={hasAccepted} onChange={(e) => setHasAccepted(e.target.checked)} />I agree with the {` `} <TermsLink />
          </Box>

          <StyledButton
            onClick={handleSubmit}
            disabled={!hasAccepted}
            variant="contained"
            color="purple"
            height={50}
            style={{ minWidth: 200 }}
          >
            Continue
          </StyledButton>
        </DialogActions>
      </Dialog>
    </Backdrop>
  );
};

export default AcceptTnc;
