import React from 'react';
import { CustomSelect } from '../../../../../../../common/Styleguide/Common/Select';
import { Option } from '@mui/base';
import { HALF_INPUT_WIDTH } from '../../UpdateSubmission/UpdateSubmission.types';

export type Unity56Encoding = 'brotli' | 'default' | 'gzip';
export interface UnityCompressionSelectProps {
  unity56Encoding?: Unity56Encoding;
  onChange: (newEncoding: Unity56Encoding) => void;
  isNonEditable?: boolean;
}

const UnityCompressionSelect: React.FC<UnityCompressionSelectProps> = (props) => {
  const { unity56Encoding, isNonEditable } = props;

  return (
    <CustomSelect
      placeholder="Select"
      value={unity56Encoding ? unity56Encoding : ''}
      onChange={(_e: any, newValue: any) => {
        const { onChange } = props;
        onChange(newValue);
      }}
      style={{ width: HALF_INPUT_WIDTH }}
      disabled={isNonEditable}
    >
      <Option value="brotli">Brotli</Option>
      <Option value="gzip">GZIP</Option>
    </CustomSelect>
  );
};

export default UnityCompressionSelect;
