import { gql } from '@apollo/client';
import { SubmissionStatus } from '../../../../../common/domain/submission';
import { UploadedFile } from '../../../../../common/domain/upload';
import { QAResult } from '../QATool/components/SubmitManager';
import { GAME_STATUS, GameCoverWithUploadedFile } from '../../../../../common/graphql/games/game';
import { Developer } from '../../Admin/Developer/developer-graphql';
import { GAME_ORIENTATION } from '../../../../../common/CzyForm/OrientationSelector';
import { TRANSLATED_ENTRY_FRAGMENT } from '../../../../../common/graphql/translatedEntry/translated-entry-graphql';
import {
  DeveloperDailyRevenueStat,
  DeveloperGameStatsAnalytics,
  DeveloperGameStatsForTimespan,
} from '../Games/userDeveloperSubmission-graphql';
import { TranslatedEntry } from '../../../../../common/i18n';
import { SubmissionAPSDetail } from '../SubmitGame/submit-game-graphql';
import { MultiplayerOptions } from './GameFilesForm/FilesFormData';
import { GameLoaderType } from '../../../../../common/domain/game';

export type SubmissionUnity56Encoding = 'brotli' | 'gzip' | 'default';

export interface Submission {
  gameName: string;
  gameStatus: GAME_STATUS;
  id: string;
  status: SubmissionStatus;
  createdAt: string;
  feedback: FeedbackType;
  gameFiles: UploadedFile[];
  description: string;
  controls: string;
  playStoreLink: string;
  appStoreLink: string;
  steamStoreLink: string;
  fullscreenable: boolean;
  isIOSFriendly: boolean;
  isAndroidFriendly: boolean;
  orientation: GAME_ORIENTATION | null;
  iframeLink: string;
  gameLoaderType: GameLoaderType;
  categoryId: string;
  tagIds: string[];
  gameCovers: GameCoverWithUploadedFile[];
  slug: string;
  unity56Encoding?: SubmissionUnity56Encoding;
  filesChanged?: boolean;
  coverChanged?: boolean;
  iframeLinkChanged?: boolean;
  qaResult?: QAResult;
  developer?: Developer;
  unitySaveFileNames?: string[];
  apsDetail?: SubmissionAPSDetail;
  playStoreDownloads?: number;
  appStoreDownloads?: number;
  steamDownloads?: number;
  hasIAP?: boolean;
  isChromebookFriendly?: boolean;
  multiplayerOptions?: MultiplayerOptions;
  requiresDevQaCheck?: boolean;
}

export type FeedbackType = Review[];

export interface Review {
  comment: string;
  postedOn: string;
  authorName: string;
}

export interface GetMySubmissionQuery {
  me: {
    submission: Submission;
  };
}

export const GET_MY_SUBMISSION_QUERY = gql`
  query GetMySubmission($id: String!) {
    me {
      id
      submission(id: $id) {
        id
        gameName
        gameStatus
        categoryId
        tagIds
        description
        controls
        iframeLink
        fullscreenable
        isIOSFriendly
        isAndroidFriendly
        orientation
        steamStoreLink
        appStoreLink
        playStoreLink
        playStoreDownloads
        appStoreDownloads
        steamDownloads
        status
        createdAt
        gameLoaderType
        slug
        gameFiles {
          uploadId
          path
          size
          temporaryUrl
        }
        gameCovers {
          size
          uploadedFile {
            uploadId
            path
            size
            temporaryUrl
          }
        }
        feedback {
          comment
          authorName
          postedOn
        }
        unity56Encoding
        unitySaveFileNames
        apsDetail {
          progressType
        }
        filesChanged
        coverChanged
        iframeLinkChanged
        qaResult {
          unfulfilledMainRequirements
          SDKImplemented
          SDKFeatures
          failedSDKFeatures
          adScenario
        }
        developer {
          id
          hasReleaseRights
        }
        hasIAP
        isChromebookFriendly
        multiplayerOptions {
          isMultiplayer
          supportsInstantJoin
          hasModeratedChat
          minLobbySize
          maxLobbySize
        }
        requiresDevQaCheck
      }
    }
  }
`;

export interface Game {
  addedOn: string;
}

export interface GetMyGameQuery {
  gameBySlug: Game;
}

export const GET_MY_GAME_QUERY = gql`
  query GetMyGame($slug: String!) {
    gameBySlug(slug: $slug) {
      id
      addedOn
    }
  }
`;

export interface MyGameStatsQuery {
  gameBySlug: GameTotalData;
}

export interface GameTotalData {
  slug: string;
  id: string;
  name: TranslatedEntry[];
  status: GAME_STATUS;
  crazygamesUrl: string;
  devStats: DeveloperGameStatsForTimespan;
  coverUrl: string;
}

export const MY_GAME_STATS_QUERY = gql`
  query gameStatsForGame($slug: String!, $period: StatsForPeriod!, $options: StatsForPeriodOptionsInput) {
    gameBySlug(slug: $slug) {
      id
      slug
      name {
        ...TranslatedEntryFragment
      }
      status
      coverUrl
      crazygamesUrl
      devStats(period: $period, options: $options) {
        from
        until
        revenueShare {
          total
        }
        analytics {
          pageviews
          users
          avgTimeOnPage
          rating
          totalVotes
          mobileUsers
          retentionD1
          retentionD7
          returningPlayers
          avgConversion
          avgLoadingTime
          issues
        }
      }
    }
  }
  ${TRANSLATED_ENTRY_FRAGMENT}
`;

export interface GameDailyData {
  id: string;
  dailyRevenueStats: DeveloperDailyRevenueStat[];
  dailyStats: DeveloperDailyStat[];
}

export interface DeveloperDailyStat extends Omit<DeveloperGameStatsAnalytics, 'rating' | 'totalVotes'> {
  date: string; // Ordered from oldest to newest
}

export interface MyGameDailyStatsQuery {
  gameBySlug: GameDailyData;
}

export const MY_GAME_DAILY_STATS_QUERY = gql`
  query gameStatsForGame($slug: String!, $options: StatsForPeriodOptionsInput!) {
    gameBySlug(slug: $slug) {
      id
      dailyRevenueStats(options: $options) {
        date
        revenueShareDaily {
          total
        }
      }
      dailyStats(options: $options) {
        date
        pageviews
        users
        avgTimeOnPage
        mobileUsers
        retentionD1
        retentionD7
        returningPlayers
        avgConversion
        avgLoadingTime
        issues
      }
    }
  }
`;
