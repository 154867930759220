import React from 'react';
import { SubmissionStatus } from '../../../../../../common/domain/submission';
import { FilesFormProblem, InfoFormProblem } from '../../SubmitGame/submission-errors';
import { buildEmptySubmission } from '../../SubmitGame/submission-helpers';
import { FilesFormData, validateFilesForm, buildDefaultFilesFormData } from '../GameFilesForm/FilesFormData';
import { InfoFormData, validateInfoForm, buildDefaultInfoFormData } from '../GameInfoForm/InfoFormData';
import { RevenueShareFormData, buildDefaultRevenueShareFormData, validateRevenueShareForm } from '../RevenueShareForm/RevenueShareFormData';
import { Submission } from '../submission-graphql';
import { convertGameCoversArrayToObject } from '../../../../../../common/graphql/games/game-covers-helper';
import { NON_RELEASED_STATUSES } from '../UpdateSubmission/UpdateSubmission.types';
import cleanCopy from '../../../../../../common/graphql/clean-copy';

interface SubmissionProviderData {
  infoForm: InfoFormData;
  filesForm: FilesFormData;
  infoFormProblems?: InfoFormProblem[];
  fileFormProblems?: FilesFormProblem[];
  attemptedToSave?: boolean;
  submission?: Submission;
  revalidateByQA?: boolean;
  revenueShareFormValid?: boolean;
  revenueShareForm: RevenueShareFormData;
  isNonEditable: boolean;
  isReleased: boolean;
  modified: boolean;
  /** Validate files size/count limits only for new submissions, so that existing ones can still update without any troubles.  */
  validateFileLimits: boolean;
}

export interface SubmissionContextData extends SubmissionProviderData {
  updateInfoForm: (infoForm: InfoFormData) => void;
  updateFilesForm: (filesForm: FilesFormData) => void;
  updateRevenueShareForm: (revenueShareForm: RevenueShareFormData) => void;
  setAttemptedToSave: (attemptedToSave: boolean) => void;
  setIsModified: (isModified: boolean, onSet?: () => void) => void;
}

export const SubmissionContext = React.createContext<SubmissionContextData>({
  infoForm: buildDefaultInfoFormData(),
  filesForm: buildDefaultFilesFormData(),
  revenueShareForm: buildDefaultRevenueShareFormData(),
  infoFormProblems: [],
  fileFormProblems: [],
  attemptedToSave: false,
  revenueShareFormValid: false,
  revalidateByQA: false,
  isNonEditable: false,
  isReleased: false,
  modified: false,
  validateFileLimits: false,
  updateInfoForm: () => {},
  updateFilesForm: () => {},
  updateRevenueShareForm: () => {},
  setAttemptedToSave: () => {},
  setIsModified: () => {},
});

interface SubmissionProviderProps {
  /** Initial submission is present when editing a submission. It's absence means this is a new submission */
  initialSubmission?: Submission;
  children?: React.ReactNode;
}

interface SubmissionState extends SubmissionContextData {}

class SubmissionProvider extends React.Component<SubmissionProviderProps, SubmissionState> {
  constructor(props: SubmissionProviderProps) {
    super(props);
    const { initialSubmission } = this.props;
    const newEmptySubmission = buildEmptySubmission();

    const infoForm: InfoFormData = initialSubmission
      ? {
          name: initialSubmission.gameName,
          category: initialSubmission.categoryId,
          tags: initialSubmission.tagIds,
          description: initialSubmission.description,
          controls: initialSubmission.controls,
          playUrl: initialSubmission.playStoreLink,
          appstoreUrl: initialSubmission.appStoreLink,
          steamUrl: initialSubmission.steamStoreLink,
          playStoreDownloads: initialSubmission.playStoreDownloads ? `${initialSubmission.playStoreDownloads}` : null,
          appStoreDownloads: initialSubmission.appStoreDownloads ? `${initialSubmission.appStoreDownloads}` : null,
          steamDownloads: initialSubmission.steamDownloads ? `${initialSubmission.steamDownloads}` : null,
        }
      : buildDefaultInfoFormData();

    const filesForm: FilesFormData = initialSubmission
      ? ({
          gameLoaderType: initialSubmission.gameLoaderType,
          isFullscreenable: initialSubmission.fullscreenable,
          isIOSFriendly: !!initialSubmission.isIOSFriendly,
          isAndroidFriendly: !!initialSubmission.isAndroidFriendly,
          orientation: initialSubmission.orientation,
          iframeLink: initialSubmission.iframeLink,
          files: initialSubmission.gameFiles,
          gameCovers: convertGameCoversArrayToObject(initialSubmission.gameCovers),
          uploadInProgress: false,
          unity56Encoding: initialSubmission.unity56Encoding,
          // the frontend for now handles only a single file name, however the backend stores an array
          unitySaveFileName: initialSubmission.unitySaveFileNames?.[0],
          progressSaveType: initialSubmission.apsDetail?.progressType,
          hasIAP: initialSubmission.hasIAP || false,
          isChromebookFriendly: initialSubmission.isChromebookFriendly || false,
          multiplayerOptions: initialSubmission.multiplayerOptions ? cleanCopy(initialSubmission.multiplayerOptions, []) : undefined,
        } as FilesFormData)
      : newEmptySubmission.filesForm;

    const validateFileLimits = !initialSubmission;

    this.state = {
      revalidateByQA: false,
      infoFormProblems: validateInfoForm(infoForm),
      fileFormProblems: validateFilesForm(filesForm, validateFileLimits),
      revenueShareFormValid: false,
      submission: initialSubmission,
      revenueShareForm: newEmptySubmission.revenueShareForm,
      infoForm: infoForm,
      filesForm: filesForm,
      isNonEditable: initialSubmission?.status === 'REJECTED',
      isReleased: initialSubmission ? !NON_RELEASED_STATUSES.includes(initialSubmission.status) : false,
      modified: false,
      validateFileLimits,
      updateInfoForm: this.updateInfoForm,
      updateFilesForm: this.updateFilesForm,
      updateRevenueShareForm: this.updateRevenueShareForm,
      setAttemptedToSave: this.setAttemptedToSave,
      setIsModified: this.setIsModified,
    };
  }

  render() {
    return <SubmissionContext.Provider value={this.state}>{this.props.children}</SubmissionContext.Provider>;
  }

  private setAttemptedToSave = (attemptedToSave: boolean) => {
    this.setState({ attemptedToSave });
  };
  private setIsModified = (isModified: boolean, onSet?: () => void) => {
    this.setState({ modified: isModified }, onSet);
  };

  private updateInfoForm = (infoForm: InfoFormData) => {
    const infoFormProblems = validateInfoForm(infoForm);
    this.setState({ infoForm, infoFormProblems, modified: true });
  };

  private updateFilesForm = (filesForm: FilesFormData) => {
    const fileFormProblems = validateFilesForm(filesForm, this.state.validateFileLimits);
    this.setState({ filesForm, fileFormProblems, modified: true, revalidateByQA: this.needToReValidateByQA(filesForm) });
  };

  private updateRevenueShareForm = (revenueShareForm: RevenueShareFormData) => {
    const revenueShareFormValid = validateRevenueShareForm(revenueShareForm).length === 0;
    this.setState({ revenueShareForm, revenueShareFormValid });
  };

  private needToReValidateByQA(newForm: FilesFormData): boolean {
    const current = this.state.filesForm;
    if (current.gameLoaderType !== newForm.gameLoaderType) {
      return true;
    }
    if (current.iframeLink !== newForm.iframeLink) {
      return true;
    }
    if (current.unity56Encoding !== newForm.unity56Encoding) {
      return true;
    }
    const previousFiles = current.files;
    const nextFiles = newForm.files;
    if (previousFiles === null && nextFiles === null) {
      return false;
    }
    if ((previousFiles === null && nextFiles !== null) || (previousFiles !== null && nextFiles === null)) {
      return true;
    }
    const previousIds = previousFiles!.map((file) => file.uploadId).sort();
    const nextIds = nextFiles!.map((file) => file.uploadId).sort();
    return JSON.stringify(previousIds) !== JSON.stringify(nextIds);
  }
}

export default SubmissionProvider;
