import { gql } from '@apollo/client';
import { mutate } from '../../../../common/graphql';
import { LOCALE } from '../../../../common/i18n';
import { ROLE_V2 } from '../../../../common/roles';

export const PROFILE_QUERY = gql`
  query {
    me {
      id
      name
      email
      publicProfile {
        contactEmail
        displayName
        tiktok
        discord
        facebook
        instagram
        twitter
        youtube
      }
      locale
      hasAcceptedLegal
      hasOptedOutUserFeedbackEmail
      roles
    }
  }
`;

export const PROFILE_BILLING_QUERY = gql`
  query {
    billing {
      paymentDetailsUrl
      invoiceHistoryUrl
      paymentHistoryUrl
    }
  }
`;

export interface DeveloperPublicProfile {
  contactEmail?: string;
  displayName?: string;
  tiktok?: string;
  discord?: string;
  facebook?: string;
  instagram?: string;
  twitter?: string;
  youtube?: string;
}

export interface DeveloperProfile {
  id: string;
  name: string;
  email: string;
  publicProfile: DeveloperPublicProfile;
  locale?: LOCALE;
  hasAcceptedLegal: boolean;
  hasOptedOutUserFeedbackEmail: boolean;
  roles: ROLE_V2[];
}

export interface ProfileQuery {
  me: DeveloperProfile;
}

export interface DeveloperProfileBillingUrls {
  paymentDetailsUrl: string;
  invoiceHistoryUrl: string;
  paymentHistoryUrl: string;
}

export interface ProfileBillingQuery {
  billing: DeveloperProfileBillingUrls;
}

const DEVELOPER_ACCOUNT_GRANULAR_UPDATE_MUTATION = gql`
  mutation saveProfileGranular($input: DeveloperProfileGranularUpdateInput!) {
    developerProfileGranularUpdate(input: $input) {
      id
      name
      email
      hasAcceptedLegal
      hasOptedOutUserFeedbackEmail
    }
  }
`;

const PUBLIC_PROFILE_MUTATION = gql`
  mutation savePublicProfile($input: DeveloperPublicProfileUpdateInput!) {
    developerPublicProfileUpdate(input: $input) {
      needToVerifyMail
    }
  }
`;

const VALIDATE_CONTACT_EMAIL_MUTATION = gql`
  mutation verifyContactEmail($input: DeveloperVerifyContactEmailInput!) {
    developerVerifyContactEmail(input: $input)
  }
`;

const ACCOUNT_CREATE = gql`
  mutation createProfile {
    developerCreate {
      id
      name
      email
      hasAcceptedLegal
      hasOptedOutUserFeedbackEmail
    }
  }
`;

export interface AccountCreateMutation {
  developerCreate: DeveloperProfile;
}

export interface PublicProfileMutation {
  developerPublicProfileUpdate: { needToVerifyMail: boolean };
}

export interface ValidateContactEmailMutation {
  developerVerifyContactEmail: boolean;
}

export interface DeveloperProfileGranularUpdateInput {
  name?: string;
  acceptTermsVersion?: string;
  hasOptedOutUserFeedbackEmail?: boolean;
}

export interface DeveloperProfileGranularUpdateMutation {
  developerProfileGranularUpdate: DeveloperProfile;
}

export interface DeveloperPublicProfileUpdateInput {
  contactEmail?: string;
  displayName?: string;
  tiktok?: string;
  discord?: string;
  instagram?: string;
  facebook?: string;
  twitter?: string;
  youtube?: string;
}

export interface ValidateContactEmailInput {
  id: string;
  code: string;
}

export function granularSaveAccount(input: DeveloperProfileGranularUpdateInput) {
  return mutate<DeveloperProfileGranularUpdateInput, DeveloperProfileGranularUpdateMutation>(
    DEVELOPER_ACCOUNT_GRANULAR_UPDATE_MUTATION,
    input,
  );
}

export function savePublicProfile(input: DeveloperPublicProfile) {
  return mutate<DeveloperPublicProfile, PublicProfileMutation>(PUBLIC_PROFILE_MUTATION, input, { refetchQueries: [PROFILE_QUERY] });
}

export function validateContactEmail(input: ValidateContactEmailInput) {
  return mutate<ValidateContactEmailInput, ValidateContactEmailMutation>(VALIDATE_CONTACT_EMAIL_MUTATION, input);
}

export function createAccount() {
  return mutate<null, AccountCreateMutation>(ACCOUNT_CREATE, null);
}
