import React from 'react';
import {
  GameLoaderType,
  gameLoaderTypeToName,
  AVAILABLE_DEVELOPER_LOADER_TYPES,
  UNITY_GAME_LOADERS,
} from '../../../../../../common/domain/game';
import { DOCS_URL } from '../../../../SideMenu/Menu';
import { StyledBodyText } from '../../../../../../common/Styleguide/Common/Text';
import { CustomSelect } from '../../../../../../common/Styleguide/Common/Select';
import { Option } from '@mui/base';
import { HALF_INPUT_WIDTH } from '../UpdateSubmission/UpdateSubmission.types';

function gameLoaderTypeOptions() {
  return AVAILABLE_DEVELOPER_LOADER_TYPES.map((key) => {
    return {
      label: gameLoaderTypeToName(key),
      value: key,
    };
  });
}

interface TypeSelectProps {
  gameLoaderType?: GameLoaderType;
  disabled?: boolean;
  onGameLoaderTypeChange: (newValue: GameLoaderType) => void;
  error?: boolean;
}

export const TypeSelect: React.FunctionComponent<TypeSelectProps> = ({
  onGameLoaderTypeChange,
  gameLoaderType,
  disabled,
  error = false,
}) => {
  return (
    <>
      <StyledBodyText variant="h3" sx={{ marginY: 1.25 }}>
        Game Type <span>*</span>
      </StyledBodyText>
      <CustomSelect
        value={gameLoaderType || ''}
        placeholder="Select"
        error={error}
        disabled={disabled}
        onChange={(_e: any, newValue: any) => {
          onGameLoaderTypeChange(newValue);
        }}
        style={{ width: HALF_INPUT_WIDTH }}
      >
        {gameLoaderTypeOptions().map((option) => {
          return (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          );
        })}
      </CustomSelect>
      {gameLoaderType && UNITY_GAME_LOADERS.includes(gameLoaderType) && (
        <StyledBodyText color="white30" variant="bodyLower">
          These{' '}
          <a href={`${DOCS_URL}/sdk/unity/resources/export-tips/`} target="_blank" rel="noreferrer">
            Unity WebGL export tips
          </a>{' '}
          may help you optimize your game, thus increasing your revenue.
        </StyledBodyText>
      )}
    </>
  );
};

export default TypeSelect;
