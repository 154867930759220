import * as React from 'react';

export const privacyUrl = 'https://www.crazygames.com/privacy-policy';

interface PrivacyLinkProps {
  style?: React.CSSProperties;
}

const PrivacyLink: React.FC<PrivacyLinkProps> = ({ style }) => (
  <a style={style} href={privacyUrl} target="_blank" rel="noopener noreferrer">
    Privacy Policy
  </a>
);

export default PrivacyLink;
